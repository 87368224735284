import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import "../components/blog-styles.scss"
import marked from "marked"

// import avatar from "../images/blog/avatar-pic.png"

// import blogImg from "../images/blog/blog-img.png"
// import blogImg2 from "../images/blog/blog-img2.png"

class BlogPostTemplate extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.description
    const post = this.props.data.post
    console.log(post)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        <div className="blog-template-wrapper">
          <div className="container-fluid main-section">
            <div className="container inner-section">
              <div
                className="blog-heading"
                style={{
                  background: `url(${post.mainImage.asset.fluid.src}) no-repeat center`,
                  backgroundSize: "cover",
                }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-8 blog-heading-inner">
                      <h2 className="text-orange mb-5 mt-0">
                        <strong>{post.title}</strong>
                        <br />
                      </h2>
                      <div className="row mt-5 pt-5">
                        <div className="col-lg-1">
                          <img
                            loading="lazy"
                            src={post.authors[0].person.image.asset.fluid.src}
                            style={{ borderRadius: "50%" }}
                            alt={post.authors[0].person.name}
                          />
                        </div>
                        <div className="col-lg-3">
                          <p className="text-white">
                            {post.authors[0].person.name}
                          </p>
                        </div>
                        <div className="col-lg-5">
                          <p className="text-white">{post.publishedAt}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-entries">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="blog-contents">
                      <div
                        dangerouslySetInnerHTML={{ __html: marked(post.body) }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default BlogPostTemplate

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    post: sanityPost(slug: { current: { eq: $slug } }) {
      id
      title
      body
      excerpt
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      slug {
        current
      }
      authors {
        person {
          name
          image {
            asset {
              fluid {
                src
              }
            }
          }
        }
      }
      publishedAt(formatString: "MMMM DD, YYYY")
    }
  }
`
